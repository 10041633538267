import React from "react";
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ApiService from "../services/api-service";
import Button from "@mui/material/Button";
import { useUserData } from "../state/user-context";
import { Link } from "gatsby";
import ClientOnly from "../components/ClientOnly";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Typography } from "@mui/material";

function Logout() {
  const { state, clearUserData } = useUserData();
  const [isLoggedOut, setIsLoggedOut] = useState(!state.isAuthenticated);
  const svc = new ApiService();

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("sm")
  );

  useEffect(() => {
    if (!isLoggedOut) {
      svc
        .logout()
        .then(() => {
          setIsLoggedOut(true);
          clearUserData();
        })
        .catch((err) => {
          console.error("logoutCmp", err);
        });
    }
  }, []);

  const loggedOutView = (
    <Stack spacing={2} maxWidth="500px" minWidth={isSmallScreen ? "100%" : ""}>
      <Typography textAlign="center">
        You have been successfully logged out.
      </Typography>
      <Button component={Link} to="/">
        Back to home
      </Button>
    </Stack>
  );

  return (
    <Box display="flex" justifyContent="center" sx={{ px: "1rem" }}>
      {!isLoggedOut ? (
        <CircularProgress />
      ) : (
        <ClientOnly transition="fade">{loggedOutView}</ClientOnly>
      )}
    </Box>
  );
}

export default Logout;
